<template>
  <div class="home">
    <div class="antd-pro-pages-dashboard-analysis-twoColLayout" :class="!isMobile && 'desktop'">
      <a-row :gutter="24" type="flex" :style="{ marginTop: '24px' }">
        <!-- <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24"> -->
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card :loading="loading" :bordered="false" title="RMA Status" :style="{ height: '100%' }">
            <a-row :gutter="68">
              <a-col :xs="24" :sm="12" :style="{ marginBottom: ' 24px'}">
                <a-timeline>
                  <a-timeline-item>
                    Create RMA
                    <p>2021-03-06 08:00:00</p>
                  </a-timeline-item>
                  <a-timeline-item>
                    Send to Repair
                    <p>2021-03-06 08:00:00</p>
                  </a-timeline-item>
                  <a-timeline-item>
                    Waiting Package
                    <p>2021-03-06 08:00:00</p>
                  </a-timeline-item>
                </a-timeline>
              </a-col>
              <a-col :xs="24" :sm="12" :style="{ marginBottom: ' 24px'}">
                <a-timeline>
                  <a-timeline-item>
                    Create RMA
                    <p>2021-03-06 08:00:00</p>
                  </a-timeline-item>
                  <a-timeline-item>
                    Send to Repair
                    <p>2021-03-06 08:00:00</p>
                  </a-timeline-item>
                  <a-timeline-item>
                    Waiting Package
                    <p>2021-03-06 08:00:00</p>
                  </a-timeline-item>
                </a-timeline>
              </a-col>
            </a-row>
          </a-card>
        </a-col>
        <a-col :xl="12" :lg="24" :md="24" :sm="24" :xs="24">
          <a-card class="antd-pro-pages-dashboard-analysis-salesCard" :loading="loading" :bordered="false" title="User Information" :style="{ height: '100%' }">
            <a-descriptions size="small" :column="isMobile ? 1 : 2">
              <a-descriptions-item label="RMA No: ">APMRMA-9000000001</a-descriptions-item>
              <a-descriptions-item label="Email: ">eahhing@apm.mc</a-descriptions-item>
              <a-descriptions-item label="Phone: ">+852 8888 8888</a-descriptions-item>
              <a-descriptions-item label="Created By">
                <a href="#">APM HK Element</a>
              </a-descriptions-item>
              <!-- <a-descriptions-item label="生效日期">2017-07-07 ~ 2017-08-08</a-descriptions-item> -->
              <!-- <a-descriptions-item label="备注">请于两个工作日内确认</a-descriptions-item> -->
            </a-descriptions>
          </a-card>
        </a-col>
      </a-row>
    </div>
    <a-card :loading="loading" :bordered="false" title="Items After Sales Service Requested for" :style="{ height: '100%' }">  
      <!-- <a-list item-layout="horizontal" :data-source="data">
        <template #renderItem="{ item }">
          <a-list-item>
            <a-list-item-meta
              description="NO-SIZE"
            >
              <template #title>
                <a href="https://www.antdv.com/">{{ item.productSku }}</a>
              </template>
              <template #avatar>
                <a-image :width="100" src="https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/a/c/ac3350ox.jpg" />
              </template>
            </a-list-item-meta>
          </a-list-item>
        </template>
      </a-list> -->
      <a-list size="large">
        <a-list-item :key="index" v-for="(item, index) in data">
          <a-list-item-meta :description="item.description">
            <!-- <a-avatar size="large" shape="square" :src="item.avatar"/>
            <p>{{ item.name }}</p> -->
            <template #title>
              <a href="https://www.apm.mc/en/{{ item.name }}" target="_blank">{{ item.name }}</a>
              <p>{{ item.size }}</p>
            </template>
            <template #avatar>
              <a-image :width="100" :src="item.avatar"/>
            </template>
          </a-list-item-meta>
          
          <!-- <div>
            <a @click="edit(item)">编辑</a>
          </div> -->
          <div>
            <a-dropdown>
              <a-menu>
                <a-menu-item><a>Remove</a></a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
          <!-- <div class="list-content">
            <div class="list-content-item">
              <span>Size</span>
              <p>{{ item.size }}</p>
            </div>
            
          </div> -->
        </a-list-item>
      </a-list>
      
    </a-card>
  </div>
</template>
<script>
  const data = []
  data.push({
    name: 'AC3350OX',
    avatar: 'https://www.apm.mc/media/catalog/product/cache/74c1057f7991b4edb2bc7bdaa94de933/a/c/ac3350ox.jpg',
    description: 'Triple Météorites Adjustable Necklace - Silver',
    size: 'NO-SIZE',
    startAt: '2018-07-26 22:44',
    progress: {
      value: 10
    }
  })

  export default {
    data() {
      return {
      
      }
    },
    mounted() {
    
    },
    setup() {
      return {
        data,
      // columns,
      // dataSource
      }
    },
  }
</script>
<style lang="scss" scoped>
.ant-avatar-lg {
    width: 48px;
    height: 48px;
    line-height: 48px;
}
.list-content-item {
    color: rgba(0, 0, 0, .45);
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    margin-left: 40px;
    span {
        line-height: 20px;
    }
    p {
        margin-top: 4px;
        margin-bottom: 0;
        line-height: 22px;
    }
}
</style>